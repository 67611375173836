import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { IoMdHome } from "react-icons/io";
import { Wrapper, Item } from "./styles";
import { GiPill } from "react-icons/gi";
import { MdHelp, MdSettings } from "react-icons/md";
import { FaTrophy } from "react-icons/fa";

import colors from "../../shared/colors";
import { ConfigContext } from "../../shared/contexts/ConfigContext";

function TabBottom() {
  let history = useHistory();
  const [currentMenu, setCurrent] = React.useState();
  const { footerVisible, menu, changeMenuOption, prev, changePrev } = useContext(ConfigContext);

  useEffect(() => {
  })

  console.log(window.location.pathname)
  setTimeout(() => {
    changeMenuBottom();
  }, 1);

  function changeMenu(menu) {
    navigationTabBar(menu);
    setCurrent(menu);
  }

  window.addEventListener('popstate', function (event) {
    changeMenuBottom();
  });

  const changeMenuBottom = () => {
    if(window.location.pathname == "/pills"){
      setCurrent(2);
      changeMenuOption(2);
    }
    if(window.location.pathname == "/home"){
      setCurrent(1);
      changeMenuOption(1);
    }
    if(window.location.pathname == "/questions"){
      setCurrent(3);
      changeMenuOption(3);
    }
    if(window.location.pathname == "/rank"){
      setCurrent(4);
      changeMenuOption(4);
    }
    if(window.location.pathname == "/settings"){
      setCurrent(5);
      changeMenuOption(5);
    }
  }

  function navigationTabBar(currentMenu) {
    switch (currentMenu) {
      case 1:
        history.push("/home");
        break;
      case 2:
        history.push("/pills");
        break;
      case 3:
        history.push("/questions");
        break;
      case 4:
        history.push("/rank");
        break;
      case 5:
        history.push("/settings");
        break;
      default:
        console.log("Erro");
    }
  }

  if (!footerVisible) return null;

  return (
    <Wrapper>
      <Item onClick={() => {
        changePrev(menu)
        changeMenu(1);
        changeMenuOption(1);
      }}>
        <IoMdHome
          color={currentMenu === 1 ? colors.primary : colors.secondaryText}
          fontSize={30}
        />
      </Item>
      <Item onClick={() => {
         changePrev(menu)
        changeMenu(2);
        changeMenuOption(2);
      }}>
        <GiPill
          color={currentMenu === 2 ? colors.primary : colors.secondaryText}
          fontSize={30}
        />
      </Item>
      <Item onClick={() => {
         changePrev(menu)
        changeMenu(3);
        changeMenuOption(3);
      }}>
        <MdHelp
          color={currentMenu === 3 ? colors.primary : colors.secondaryText}
          fontSize={30}
        />
      </Item>
      <Item onClick={() => {
         changePrev(menu)
        changeMenu(4);
        changeMenuOption(4);
      }}>
        <FaTrophy
          color={currentMenu === 4 ? colors.primary : colors.secondaryText}
          fontSize={30}
        />
      </Item>
      <Item onClick={() => {
         changePrev(menu)
        changeMenu(5);
        changeMenuOption(5);
      }}>
        <MdSettings
          color={currentMenu === 5 ? colors.primary : colors.secondaryText}
          fontSize={30}
        />
      </Item>
    </Wrapper>
  );
}

export default TabBottom;
