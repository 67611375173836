import React, { useState, createContext } from "react";

export const ConfigContext = createContext();

const ConfigProvider = ({ children }) => {
  const [headerVisible, setHeaderVisible] = useState(true);
  const [footerVisible, setFooterVisible] = useState(true);
  const [loadingVisible, setLoading] = useState(false);

  const [pill, setPill] = useState(null);
  const [menu, setMenu] = useState(1);
  const [prev, setPrev] = useState(1);
  
  const changePrev = (menu) => {
    setPrev(menu);
  };

  const changePill = (pill) => {
    setPill(pill);
  };

  const changeHeaderVisibity = (visible) => {
    setHeaderVisible(visible);
  };

  const changeFooterVisibility = (visible) => {
    setFooterVisible(visible);
  };

  const changeMenuOption = (menu) => {
    setMenu(menu);
  }

  const changeLoading = (visible) => {
    setLoading(visible);
  };

  return (
    <ConfigContext.Provider
      value={{
        headerVisible,
        changeHeaderVisibity,
        footerVisible,
        changeFooterVisibility,
        loadingVisible,
        changeLoading,
        menu,
        changeMenuOption,
        prev,
        changePrev,
        pill,
        changePill
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;
